
import { commonProps } from '~/utils/prismic';
import { LAYOUT_ACTION_FIXED_BUTTON } from '~/store/layout';

export default {
  props: commonProps,
  fetch() {
    this.setFixedButton({
      hasFixedButton: this.slice.data.hasFixedButton,
      label: this.slice.data.heroData.linkLabel,
      cta: this.slice.data.heroData.link,
    });
  },
  methods: {
    setFixedButton({ hasFixedButton, cta, label }) {
      const storeValue = hasFixedButton
        ? {
            cta,
            label: label,
            alwaysVisible: false,
          }
        : null;
      this.$store.dispatch(`layout/${LAYOUT_ACTION_FIXED_BUTTON}`, storeValue);
    },
  },
};
